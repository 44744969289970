/* Sketch.css */

@media (max-width: 600px) {
  .parent-container {
    padding-left: 3.5vw;
    padding-top: 1vh;
  }

  .sketch-container {
    height: fit-content;
    display: flex;
    margin-top: 6vh;
    flex-direction: column;
    padding-left: 0vw;
    padding-top: 2vh;
    overflow: hidden;
  }

  .my-canvas {
    border-radius: 32px;
    width: 89vw !important;
    height: 40vh !important;
    border: 1px solid #d9d9d9 !important;
  }

  .my-canvas-image {
    border-radius: 32px;
    width: 89vw !important;
    height: 63vh !important;
    border: 1px solid #d9d9d9 !important;
    margin-bottom: 15px;
  }

  .canvas-container {
    margin-bottom: 7px;
    width: 100% !important;
    touch-action: none;
  }

  .upper-tools-container {
    height: 23vh;
    width: 85vw;
    padding: 10px;
    background: #f0e4ee;
    background-blend-mode: luminosity;
    border-radius: 32px;
    margin-right: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .Ai-container {
    width: 100%;
  }

  .pencil-container {
    width: 100%;
    height: 7vh;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;
  }

  .lower-tools-container {
    width: 87vw;
    padding-top: 2vh;
    height: 12vh;
    justify-content: space-evenly;
    border-radius: 32px;
    margin-right: 2%;
    display: flex;
    flex-direction: row;
    align-items: start;
  }

  .typography {
    font-family: "DM Sans", sans-serif !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    letter-spacing: 0.02em !important;
    text-align: left !important;
    color: #4d2d66;
  }

  .small-typography {
    font-family: "DM Sans" !important;
    font-size: 0.55rem !important;
    font-weight: 400 !important;
    line-height: 18.23px !important;
    letter-spacing: 0.02em !important;
    text-align: left !important;
    color: #4d2d66;
  }

  .generate-image-button-typography {
    font-family: "DM Sans", sans-serif !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    letter-spacing: 0.02em !important;
    text-align: left !important;
  }

  .lower-container-typography {
    font-family: "DM Sans", sans-serif !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    letter-spacing: 0.02em !important;
    text-align: left !important;
    color: #7c6a8b !important;
  }

  .overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 117%;
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Adjust the opacity for the overlay effect */
    z-index: 999; /* Ensure the overlay is on top of other content */
  }

  .loadingBox {
    width: 40vw;
    height: 20vh;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
  }

  .iframe-parent-container {
    height: 100vh;
  }

  .close-button {
    position: absolute;
    top: 5;
    left: 10;
    border: 1px solid #ffffff80;
    background: #282828;
    box-shadow: 0px 8px 24px -6px #00000029;
  }
}

@media (min-width: 600px) {
  .parent-container {
    padding-left: 10vw;
    padding-top: 4vh;
  }
  .sketch-container {
    display: flex;
    margin-top: 6vh;
    flex-direction: row;
    padding-left: 0vw;
    padding-top: 6vh;
  }

  .tools-container {
    width: 100%;
  }

  .canvas-container {
    width: 100%;
  }
  .my-canvas {
    border-radius: 32px;
    width: 50vw !important;
    height: 60vh !important;
    border: 1px solid #d9d9d9 !important;
  }

  .upper-tools-container {
    height: 28vh;
    width: 15vw;
    padding: 10px;
    background: #f0e4ee;
    background-blend-mode: luminosity;
    border-radius: 32px;
    margin-right: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .pencil-container {
    width: 100%;
    height: 11vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .lower-tools-container {
    padding-top: 2vh;
    height: 12vh;
    justify-content: space-evenly;
    border-radius: 32px;
    margin-right: 2%;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .typography {
    font-family: "DM Sans" !important;
    font-size: 0.8rem !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    letter-spacing: 0.02em !important;
    text-align: left !important;
    color: #4d2d66;
  }

  .small-typography {
    font-family: "DM Sans" !important;
    font-size: 0.6rem !important;
    font-weight: 400 !important;
    line-height: 18.23px !important;
    letter-spacing: 0.02em !important;
    text-align: left !important;
    color: #4d2d66;
  }

  .generate-image-button-typography {
    font-family: "DM Sans" !important;
    font-size: 0.8rem !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    letter-spacing: 0.02em !important;
    text-align: left !important;
  }

  .lower-container-typography {
    font-family: "DM Sans" !important;
    font-size: 0.8rem !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    letter-spacing: 0.02em !important;
    text-align: left !important;
    color: #7c6a8b !important;
  }

  .overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Adjust the opacity for the overlay effect */
    z-index: 999; /* Ensure the overlay is on top of other content */
  }

  .loadingBox {
    width: 20vw;
    height: 20vh;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
  }

  .iframe-parent-container {
    height: 100vh;
  }

  .close-button {
    border: 1px solid #ffffff80;
    background: #282828;
    box-shadow: 0px 8px 24px -6px #00000029;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .parent-container {
    padding-left: 5vw;
    padding-top: 1vh;
  }

  .sketch-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-left: 0vw;
    padding-top: 3vh;
  }

  .my-canvas {
    border-radius: 32px;
    width: 89vw !important;
    height: 63vh !important;
    border: 1px solid #d9d9d9 !important;
  }

  .canvas-container {
    width: 100%;
    margin-bottom: 7px;
  }

  .upper-tools-container {
    height: 23vh;
    width: 85vw;
    padding: 10px;
    background: #f0e4ee;
    background-blend-mode: luminosity;
    border-radius: 32px;
    margin-right: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .Ai-container {
    width: 100%;
  }

  .pencil-container {
    width: 100%;
    height: 7vh;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;
  }

  .lower-tools-container {
    width: 87vw;
    padding-top: 2vh;
    height: 12vh;
    justify-content: space-evenly;
    border-radius: 32px;
    margin-right: 2%;
    display: flex;
    flex-direction: row;
    align-items: start;
  }

  .typography {
    font-family: "DM Sans", sans-serif !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    letter-spacing: 0.02em !important;
    text-align: left !important;
    color: #4d2d66;
  }

  .generate-image-button-typography {
    font-family: "DM Sans", sans-serif !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    letter-spacing: 0.02em !important;
    text-align: left !important;
  }

  .lower-container-typography {
    font-family: "DM Sans", sans-serif !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    letter-spacing: 0.02em !important;
    text-align: left !important;
    color: #7c6a8b !important;
  }

  .overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 117%;
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Adjust the opacity for the overlay effect */
    z-index: 999; /* Ensure the overlay is on top of other content */
  }

  .loadingBox {
    width: 40vw;
    height: 20vh;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
  }

  .iframe-parent-container {
    height: 100vh;
  }
}

.canvas-container {
  width: 80%;
}

.tools-container {
  width: 20%;
}

.line-between {
  width: 100%; /* Adjust the length of the line */
  display: inline-block;
  border: 1px solid #4d2d661a;
}

.tools-container select {
  margin-top: 10px;
}

.generate-button {
  width: 100% !important;
  background: linear-gradient(
    286.47deg,
    #dbdbf5 -2.6%,
    #bf50b2 71.98%,
    #4d2d66 108.49%
  ) !important;
  color: white !important;
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 32px !important;
  cursor: pointer !important;
  margin-top: 10px !important;
  font-family: "DM Sans" !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: 0.02em !important;
  text-align: left !important;
}
/* Style for the buttons */
.clear-canvas-button {
  color: #7c6a8b !important;
  cursor: pointer;

  font-family: "DM Sans" !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: 0.02em !important;
  text-align: left !important;
}

/* Hover effect */
.clear-canvas-button:hover {
  background-color: #008c5a; /* Darker green on hover */
}

.pencil {
  display: flex !important;
  flex-direction: row !important;
  justify-content: left !important;
  padding-left: 20px !important;
  width: 100% !important;
  border-radius: 100px !important;
  height: 5vh !important;
  background: #f0e4ee !important;
  border: #f0e4ee !important;
  background-blend-mode: luminosity !important;
  color: #4d2d66 !important;
  font-family: "DM Sans" !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: 0.02em !important;
  text-align: left !important;
}

.selected {
  display: flex !important;
  flex-direction: row !important;
  justify-content: left !important;
  padding-left: 20px !important;
  background-color: white !important;
  border: white !important;
  width: 100% !important;
  border-radius: 100px !important;
  height: 5vh !important;
  box-shadow: 0px 5px 5px 0px #4d2d661a !important;
  color: #4d2d66 !important;
  font-family: "DM Sans" !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: 0.02em !important;
  text-align: left !important;
}

.symmetry {
  display: flex !important;
  flex-direction: row !important;
  justify-content: left !important;
  width: 100% !important;
  border-radius: 100px !important;
  height: 5vh !important;
  padding-left: 20px !important;
  background: #f0e4ee !important;
  border: #f0e4ee !important;
  background-blend-mode: luminosity !important;
  color: #4d2d66 !important;
  font-family: "DM Sans" !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: 0.02em !important;
  text-align: left !important;
}

.dropdown {
  background-color: white;
  border: white;
  width: 100%;
  border-radius: 100px !important;
  height: 5vh;
  box-shadow: 0px 5px 5px 0px #4d2d661a;
}

/* .typography{
  font-family: "DM Sans" !important;
  font-size: 14px !important;
  font-weight: 700 !important; 
  line-height: 24px !important;
  letter-spacing: 0.02em !important;
  text-align: left !important;
  color: #4D2D66 ; 
}

.generate-image-button-typography{
  font-family: "DM Sans" !important;
  font-size: 14px !important;
  font-weight: 700 !important; 
  line-height: 24px !important;
  letter-spacing: 0.02em !important;
  text-align: left !important;
}

.lower-container-typography{
  font-family: "DM Sans" !important;
  font-size: 14px !important;
  font-weight: 700 !important; 
  line-height: 24px !important;
  letter-spacing: 0.02em !important;
  text-align: left !important;
  color: #7C6A8B !important;
} */
