input[type='range']:not(:disabled) {
    -webkit-appearance: none;
}

input[type='range']:not(:disabled)::-webkit-slider-runnable-track {
    height: 8px;
    -webkit-appearance: none;
    background: linear-gradient(to right, #BF50B2, #BF50B2) no-repeat, linear-gradient(to right, #BF50B2, #FFFFFF) no-repeat;
    background-size: auto 100%, auto;
    background-position: left center, right center;
    margin-top: -1px;
    border-radius: 100px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
    /* Added */
}

input[type='range']:not(:disabled)::-webkit-slider-thumb {
    width: 20px;
    height: 20px;
    -webkit-appearance: none;
    background: #FFFFFF;
    border: 1px solid #BF50B2;
    border-radius: 100px;
    margin-top: -5px;
    position: relative;
    /* Added */
}

input[type='range']:not(:disabled)::-webkit-slider-runnable-track::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    /* Adjust the width of the mark */
    height: 8px;
    /* Adjust the height of the mark */
    background-color: #BF50B2;
    /* Color of the mark */
    z-index: 1;
    /* Ensure it appears above the track */
}

/* Adjust the position of the mark based on the range value */
input[type='range']:not(:disabled)[value='1']::-webkit-slider-runnable-track::after {
    left: 12.5%;
    /* Adjust left position for the minimum value */
}

input[type='range']:not(:disabled)[value='7']::-webkit-slider-runnable-track::after {
    left: 87.5%;
    /* Adjust left position for the maximum value */
}