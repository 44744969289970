.responsive-video-container {
  height: 93vh;
  width: 96vw;
  padding-left: 0vw;
  padding-top: 6vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.parent-container-video {
  padding-left: 2vw;
  padding-top: 0vh;
}

.responsive-video-iframe {
  width: 100%;
  height: 100%;
  border-radius: 16px !important;
  border: none;
  background-color: black;
}

.close-button-video {
  position: absolute !important;
  border: 1px solid #ffffff80;
  background: #282828;
  box-shadow: 0px 8px 24px -6px #00000029;
  margin-left: 5vw;
}

.iframe-parent-container {
  height: 100vh;
}
